import '../../../css/login.css'
import '../../../css//formcontrol.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnonymousJoinRequests } from '../../../redux/customerAuth';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import LoaddingRound from '../../LoadingRound';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function WantJoin() {

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [address, setAddress] = useState("");
    const [comment, setComment] = useState("");

    const [files, setFile] = useState([]);

    let [joinmessage, setJoinMessage] = useState("");

    const { loading } = useSelector((state) => state.customer);

    const dispatch = useDispatch();

    function setFileData(data) {
        if (data !== undefined) {
            setFile(data);
        }
    }

    function wnatjoinrequest(event) {
        event.preventDefault();        
        const joinData = new FormData();
        joinData.append("email", email);
        joinData.append("name", name);
        joinData.append("contact", contact);
        joinData.append("address", address);
        joinData.append("comment", comment);
        joinData.append('files', files);
        dispatch(AnonymousJoinRequests(joinData)).then((result) => {
            if (result.payload.error === "") {
                setJoinMessage(<div className="request-data">Request Submited <br />We will Contact Soon</div>);
            } else {
                setJoinMessage(<div className="request-data">Some Error <br />Contact with Admin</div>);
            }
        });
    };


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Want to Join Us | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="login-cont myRow">

                    <div className="col-md-12 login-details">
                        <div className="cont-details">
                            <div className="heading-cont">Join as Associate</div>
                            <div className="body-cont" id="login">
                                {
                                    (joinmessage === "") ?
                                        <form id="wnatjoinrequest" onSubmit={(event) => wnatjoinrequest(event)}>
                                            <div className="form__group field">
                                                <input type="text" className="form__field" placeholder="Enter Name" id="name" required onChange={(e) => setName(e.target.value)} />
                                                <label className="form__label">Name</label>
                                            </div>
                                            <div className="form__group field">
                                                <input type="email" className="form__field" placeholder="Enter Email" id="useremail" required onChange={(e) => setEmail(e.target.value)} />
                                                <label className="form__label">Email</label>
                                            </div>
                                            <div className="form__group field">
                                                <input type="text" className="form__field" placeholder="Enter Contact No." id="usercontact" required onChange={(e) => setContact(e.target.value)} />
                                                <label className="form__label">Contact No.</label>
                                            </div>
                                            <div className="form__group field">
                                                <input type="text" className="form__field" placeholder="Enter Address" id="Address" required onChange={(e) => setAddress(e.target.value)} />
                                                <label className="form__label">Address</label>
                                            </div>
                                            <div className="form__group field">
                                                <textarea className="form__field textarea" id="whatdowant" onChange={(e) => setComment(e.target.value)} placeholder="Enter Extra Comment"></textarea>
                                                <label className="form__label">Extra Comment</label>
                                            </div>
                                            <div className="form__group field">
                                                <label className="file__label">CV</label>
                                                <div className='uploadfile-section'>
                                                    <div className='upload-input'>
                                                        <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => setFileData(e.target.files[0])} />
                                                        <div className='file-cont'>
                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                        </div>
                                                    </div>
                                                    <div className='upload-filename'>{files.name}</div>
                                                </div>
                                            </div>
                                            <button type="submit" className="myloginbtn">Join Now <i className="fas fa-arrow-right"></i></button>
                                        </form> : joinmessage
                                }

                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            {
                loading ? <LoaddingRound /> : ""
            }
        </>
    )
}
export default WantJoin