import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import SideMenu from "../../layout/SideMenu";
import '../../../../css/visitrequest.css';
import React, { useState } from "react";
import HeaderNav from "../../layout/HeaderNav";
import { getallDraftRequest, updateStatusVisitRequest } from "../../../../redux/visitRequest";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';


function DraftVisitRequest() {

    const [mobNav, setMobNav] = useState(false);
    const dispatch = useDispatch();

    const [draftRequests, setDraftRequest] = useState([]);
    let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_id;

    React.useEffect(() => {
        refreshTable();
    }, []);
    function refreshTable() {
        dispatch(getallDraftRequest(customer_id)).then((result) => {
            if (result.payload.error === "") {
                setDraftRequest(result.payload.data);
            }
        });
    }

    function updateRequestStatus(visit_id, visit_status) {
        dispatch(updateStatusVisitRequest({ visit_id, visit_status })).then((result) => {
            if (result.payload.error == "") {
                refreshTable();
            }
        });
    };

    return (
        <>
            <HelmetProvider>
                < Helmet>
                    <title>Customer Draft Visit Request | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Visit Request" Suboptions="Draft Request" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request", "Draft Request"]} />
                        <div className="fulldetailstable">
                            <div className="table-heading">Draft Requests</div>
                            <div className="my-table">
                                <table className="table table-striped my-table">
                                    <thead>
                                        <tr className="table-warning">
                                            <th></th>
                                            <th>Request ID</th>
                                            <th>Request For</th>
                                            <th>Emergency</th>
                                            <th>Visit Date</th>
                                            <th>Visit Time</th>
                                            <th>Service Type</th>
                                            <th>Prefered Associate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (draftRequests !== "") ?
                                                draftRequests.map((draftRequest, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Link to={"/customer/visit-request/edit/" + draftRequest.vrid} title="Edit Request"><i className="far fa-edit"></i></Link>
                                                            <i className="fas fa-trash" title="Delete Request" onClick={(e) => updateRequestStatus(draftRequest.vrid, "deleted")}></i>
                                                        </td>
                                                        <td>{draftRequest.vrid}</td>
                                                        <td>{draftRequest.request_for}</td>
                                                        <td>{draftRequest.emergency}</td>
                                                        <td>{draftRequest.visit_date}</td>
                                                        <td>{draftRequest.visit_time}</td>
                                                        <td>{draftRequest.servicetype}</td>
                                                        <td>{draftRequest.prefered_asso}</td>
                                                    </tr>
                                                ) : <tr><td colSpan="8"><div className="no-data">No Request Found</div></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DraftVisitRequest;