import '../../../css/login.css'
import '../../../css//formcontrol.css';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnonymousVisitRequests, ResetPasswordData, loginCustomer } from '../../../redux/customerAuth';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import LoaddingRound from '../../LoadingRound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AES } from "crypto-js";

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [want, setWant] = useState("");
    const [contactinformation, setContactInformation] = useState("");
    let [errorMsg, setErrorMsg] = useState("");
    let [visitMessage, setVisitMessage] = useState("");
    let [forgotMessage, setForgotMessage] = useState("");

    const [forgetpassword, setForgetPassword] = useState(false);

    const { loading, error } = useSelector((state) => state.customer);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function loginForm(event) {
        event.preventDefault();
        let loginDatas = { email, password };
        dispatch(loginCustomer(loginDatas)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/dashboard");
            } else {
                setErrorMsg(result.payload.error);
            }
        });
    };

    function resetpassword() {
        const AccessToken = AES.encrypt(email, "ResetDataPassword").toString();
        let loginDatas = { AccessToken, email };
        dispatch(ResetPasswordData(loginDatas)).then((result) => {
            if (result.payload.error === "") {
                setForgotMessage(<div className="request-data">Password Reset Link Sent to the Email - {email}</div>);
            }else{
                setForgotMessage(<div className="request-data">Email {email} is Not Registered</div>);
            }
        });
    };

    function aVisitRequest(event) {
        event.preventDefault();
        let visistData = { want, contactinformation };
        dispatch(AnonymousVisitRequests(visistData)).then((result) => {
            if (result.payload.error === "") {
                setVisitMessage(<div className="request-data">Visit Request Submited Request ID {result.payload.data.avsid}</div>);
            }
        });
    };

    useEffect(() => {
        if (localStorage.getItem('customer-info')) {
            navigate("/customer/dashboard");
        }
    }, [])

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Customer Login | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="login-cont myRow">

                    <div className="col-md-6 login-form">
                        {
                            (!forgetpassword) ?
                                <div className="cont-details">
                                    <div className="heading-cont">Customer LogIn</div>
                                    <div className="body-cont" id="login">
                                        <form id="loginForm" onSubmit={(event) => loginForm(event)}>
                                            <div className="form__group field">
                                                <input type="email" className="form__field" placeholder="Enter Email" id="useremail" required onChange={(e) => setEmail(e.target.value)} />
                                                <label className="form__label">Email</label>
                                            </div>
                                            <div className="form__group field">
                                                <input type="password" className="form__field" placeholder="Enter Password" id="password" required onChange={(e) => setPassword(e.target.value)} />
                                                <label className="form__label">Password</label>
                                            </div>
                                            {
                                                error && (<div className="myRow"><div className='login_error'>{error}</div></div>)
                                            }
                                            <div className="myRow"><div className='login_error'>{errorMsg}</div></div>
                                            <button type="submit" className="myloginbtn">Login <i className="fas fa-arrow-right"></i></button>
                                        </form>
                                        <div className='text-cont clickable' onClick={() => setForgetPassword(true)}>Forgot Password?</div>
                                        <div className="text-cont">Not join yet ! <Link to="/customer/register/step1">Join with us</Link></div>
                                    </div>
                                </div>
                                :
                                <div className="cont-details">
                                    {(forgotMessage === "") ?
                                        <>
                                            <div className="heading-cont">Forgot Password</div>
                                            <div className="body-cont">
                                                <div className="form__group field">
                                                    <input type="email" className="form__field" placeholder="Enter Email" id="useremail" required onChange={(e) => setEmail(e.target.value)} />
                                                    <label className="form__label">Email</label>
                                                </div>
                                                <button type="button" className="myloginbtn" onClick={() => resetpassword()}>Reset Password</button>
                                                <div className='text-cont clickable' onClick={() => setForgetPassword(false)}>Wants to Login?</div>
                                                <div className="text-cont">Not join yet ! <Link to="/customer/register/step1">Join with us</Link></div>
                                            </div>
                                        </>
                                        :
                                        forgotMessage
                                    }
                                </div>

                        }
                    </div>
                    <div className="col-md-6 login-details">
                        <div className="cont-details">
                            <div className="heading-cont">Visit Request</div>
                            <div className="body-cont" id="wantRequest">
                                {
                                    (visitMessage === "") ?

                                        <form id="vr" onSubmit={(event) => aVisitRequest(event)}>
                                            <div className="form__group field">
                                                <input type="text" className="form__field" placeholder="Enter Eamil or Phone Number" id="contactinformation" required onChange={(e) => setContactInformation(e.target.value)} />
                                                <label className="form__label">Contact Information</label>
                                            </div>
                                            <div className="form__group field">
                                                <textarea className="form__field textarea" id="whatdowant" required onChange={(e) => setWant(e.target.value)} placeholder="Enter Eamil or Phone Number"></textarea>
                                                <label className="form__label">What do you want done?</label>
                                            </div>
                                            <br />
                                            <div className='myRow inputrow'>
                                                <button type="submit" className="myregisterbtn btn btn-success">Submit Your Request</button>
                                            </div>
                                        </form>
                                        : visitMessage
                                }
                                <br />
                                <div className="text-cont">Want to join our team ? <Link to="/associates/join">Join Now</Link></div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            {
                loading ? <LoaddingRound /> : ""
            }
        </>
    )
}
export default Login