import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { uploadData } from "../../../../redux/spcData";

function UploadFile(params) {
    const [mobNav, setMobNav] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { clientid } = useParams();

    const [files, setFile] = useState([]);
    const [type, setType] = useState("");

    function setFileData(data) {
        if (data !== undefined) {
            setFile(data);
        }
    }

    function editClentFrom(event) {
        event.preventDefault();
        const fileData = new FormData();        
        let customerid = JSON.parse(localStorage.getItem('customer-info')).customer_cid;
        fileData.append("customerid", customerid);
        fileData.append("clientid", clientid);
        fileData.append("Type", type);
        fileData.append('files', files);
        dispatch(uploadData(fileData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/clients");
            } 
        });       
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Upload Files | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Members" Suboptions="" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Members", "Upload Data"]} />
                        <div className="fullformdetails big-panel">
                            <form id="addnewRequest" onSubmit={(event) => editClentFrom(event)}>
                                <div className="input-formtitle">
                                    Upload Files for Member
                                </div>
                                <div className="myRow">
                                    <div className="col-md-6">
                                        <div className="input-formgroup">
                                            <label className="input-label">File Type</label>
                                            <select className="input-cell" onChange={(e) => setType(e.target.value)} required value={type}>
                                                <option value="" disabled="">Select any one</option>
                                                <option value="Aadhar Card">Aadhar Card</option>
                                                <option value="Medical Insurance Card">Medical Insurance Card</option>
                                                <option value="Other Documents">Other Documents</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-formgroup">
                                            <label className="input-label">Upload File</label>
                                            <div className='uploadfile-section'>
                                                <div className='upload-input'>
                                                    <input type="file" onChange={(e) => setFileData(e.target.files[0])} />
                                                    <div className='file-cont'>
                                                        <i className="fas fa-cloud-upload-alt"></i>
                                                    </div>
                                                </div>
                                                <div className='upload-filename'>{files.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="myRow justify-content-md-center mob-but">
                                    <div className="col-md-2">
                                        <input type="submit" value="Upload Data" className="my-button bg-green" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default UploadFile;