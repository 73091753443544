import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getclientdetails } from "../../../../redux/spcData";

function ViewClient() {

    const [mobNav, setMobNav] = useState(false);
    const [client, setClientData] = useState([]);

    const dispatch = useDispatch();

    let { clientid } = useParams();

    React.useEffect(() => {
        dispatch(getclientdetails(clientid)).then((result) => {
            if (result.payload.error === "") {
                let clientdata = result.payload.data;
                setClientData(clientdata);
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View Member | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Members" Suboptions="" onMobNav={setMobNav}/>
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Members", "View"]} />
                        <div className="fullformdetails big-panel">
                            <div className="input-formtitle">
                                Details of Member
                            </div>
                            <div className="myRow">
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Name</div>
                                    {client.fname} {(client.mname != null) ? client.mname : ""} {client.lname}
                                </div>
                                
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Status</div>
                                    {client.status}
                                </div>
                            </div>
                            <div className="myRow">
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Relationship with You</div>
                                    {client.relation}
                                </div>
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Gender</div>
                                    {client.gender}
                                </div>
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Preferred Home or Hospital</div>
                                    {client.prferedhome}
                                </div>
                            </div>
                            <div className="myRow">
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Age Range</div>
                                    {client.agerange}
                                </div>                                
                            </div>
                            <div className="myRow">
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Country</div>
                                    {client.country}
                                </div>
                                <div className="data-cont col-md-4">
                                    <div className="data-title">State</div>
                                    {client.state}
                                </div>
                                <div className="data-cont col-md-4">
                                    <div className="data-title">City</div>
                                    {client.city}
                                </div>
                            </div>
                            <div className="myRow">
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Locality</div>
                                    {client.locality}
                                </div>
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Zipcode</div>
                                    {client.zipcode}
                                </div>
                                <div className="data-cont col-md-4">
                                    <div className="data-title">Navigation Marker</div>
                                    {client.navigationmarker}
                                </div>
                            </div>
                            <div className="myRow">
                                <div className="data-cont col-md-6">
                                    <div className="data-title">Address First Line</div>
                                    {client.fladdress}
                                </div>
                                <div className="data-cont col-md-6">
                                    <div className="data-title">Address Second Line</div>
                                    {client.sladdress}
                                </div>
                            </div>
                            <br/>
                            <div className="myRow">
                                <center>
                                    <Link to={"/customer/client/upload/" + client.client_id} className="my-button bg-green">&nbsp;&nbsp; Upload Important Documents &nbsp;&nbsp;</Link>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewClient;