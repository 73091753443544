import Footer from "./component/layout/Footer";
import Header from "./component/layout/Header";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Home() {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Home | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="login-cont myRow"></div>
                <Footer />
            </div>
        </>
    )
}
export default Home;