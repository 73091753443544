import SideMenu from "../../layout/SideMenu";
import TopNav from "../../layout/TopNav";
import HeaderNav from "../../layout/HeaderNav";
import '../../../../css/customerlayout.css';
import '../../../../css/customerprofile.css';
import { Link, useNavigate } from "react-router-dom";
import { man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb } from '../../../../img/customeravatarimage';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updatePasswordCustomer } from "../../../../redux/customerProfile";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function CustomerChangePassword() {
    
    const [mobNav, setMobNav] = useState(false);
    const customeruser = JSON.parse(localStorage.getItem('customer-info'));
    const custMale = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb];
    const custFemale = [lady_1_rb, lady_2_rb, lady_3_rb];
    const custAll = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb];
    const [profilePic, setProfilePic] = useState("");
    let id = "";
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    let [disableButton, setdisableButton] = useState(true);
    let [disableField, setdisableField] = useState(true);
    let [errorMsg, setErrorMsg] = useState("");
    let [olderrorMsg, setOldErrorMsg] = useState("");

    function updateCust() {
        if (customeruser.gender === "male") {
            setProfilePic(custMale[Math.floor(Math.random() * custMale.length)]);
        } else if (customeruser.gender === "female") {
            setProfilePic(custFemale[Math.floor(Math.random() * custFemale.length)]);
        } else {
            setProfilePic(custAll[Math.floor(Math.random() * custAll.length)]);
        }
    }
    useEffect(() => {
        updateCust();
    }, []);

    if (localStorage.getItem('customer-info')) {
        id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
    }
    function checkPassword(data) {
        let errMsg = "";
        setNewPassword(data);
        if (data !== oldPassword) {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp = /.{8,}/;
            setdisableField(true);
            if (!data.match(uppercaseRegExp))
                errMsg = "At least one Uppercase\n";
            if (!data.match(lowercaseRegExp))
                errMsg += "At least one Lowercase\n";
            if (!data.match(digitsRegExp))
                errMsg += "At least one digit\n";
            if (!data.match(specialCharRegExp))
                errMsg += "At least one Special Characters\n";
            if (!data.match(minLengthRegExp))
                errMsg += "At least minumum 8 characters\n";
            setErrorMsg(errMsg);
            if (errMsg === "") {
                setdisableField(false);
            }
        } else {
            setErrorMsg("New and Old Password is Same");
            setdisableField(true);
        }
    }
    function checkRePassword(data) {
        if (data === newPassword) {
            setdisableButton(false);
        } else {
            setdisableButton(true);
        }
    }
    function changePassword(event) {
        event.preventDefault();
        let cid = id;
        let yourData = { cid, oldPassword, newPassword };
        dispatch(updatePasswordCustomer(yourData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/profile");
            } else {
                setOldErrorMsg("Password Not Match");
                document.getElementById("changePass").reset();
            }
        });
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Change Your Password | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Profile" Suboptions="Change Password" onMobNav={setMobNav}/>
                </div>
                <div className="right-sideSection">
                <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Your Profile", "Change Password"]} />
                        <div className="fulldetailstable big-panel">
                            <div className="myRow profile-backgound">
                                <div className="fullpofile-image">
                                    <img src={profilePic} alt="Profile Pic" />
                                    <Link to="/customer/profile/edit" className="my-button bg-lightgreen"><i className="fas fa-user-edit"></i> Edit Profile</Link>
                                </div>
                                <div className="profile-details">
                                    <div className="profile-item">
                                        <label>Name</label>
                                        {customeruser.fname} {customeruser.mname} {customeruser.lname}
                                    </div>
                                    <div className="profile-item">
                                        <label>Email</label>
                                        {customeruser.email}
                                    </div>
                                    <div className="myRow">
                                        <div className="col-md-6">
                                            <div className="profile-item">
                                                <label>Address</label>
                                                {customeruser.fladdress}<br />
                                                {customeruser.sladdress}<br />
                                                {customeruser.city}, {customeruser.state}<br />
                                                Zip Code - {customeruser.zipcode}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-item">
                                                <label>Country</label>
                                                {customeruser.country}
                                            </div>
                                            <div className="profile-item">
                                                <label>Locality  & Time Zone</label>
                                                {customeruser.locality} ( {customeruser.timezone} )
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="myRow addcontactfield">
                                <form id="changePass" onSubmit={(event) => changePassword(event)}>
                                    <div className="input-formtitle">
                                        Chnage Your Password
                                    </div>
                                    <div className="myRow">
                                        <div className='col-md-4'>
                                            <div className="input-formgroup">
                                                <label className="input-label">Old Password</label>
                                                <input type="password" className="input-cell" placeholder="Enter Old Password" onChange={(e) => setOldPassword(e.target.value)} id="oldpassword" required />
                                            </div>
                                            <div className='password_error'>
                                                <p>{olderrorMsg}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className="input-formgroup">
                                                <label className="input-label">New Password</label>
                                                <input type="password" className="input-cell" placeholder="Enter New Password" onChange={(e) => checkPassword(e.target.value)} id="newpassword" required />
                                            </div>
                                            <div className='password_error'>
                                                {errorMsg.split('\n').map((item, i) => (item !== "") ? <p key={i}>{item}</p> : "")}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            {(disableField) ?
                                                <div className="input-formgroup">
                                                    <label className="input-label">Rennter New Password</label>
                                                    <input type="password" className="input-cell" placeholder="Reenter New Password" onChange={(e) => checkRePassword(e.target.value)} id="renewpassword" required disabled />
                                                </div> :
                                                <div className="input-formgroup">
                                                    <label className="input-label">Reenter New Password</label>
                                                    <input type="password" className="input-cell" placeholder="Reenter New Password" onChange={(e) => checkRePassword(e.target.value)} id="renewpassword" required />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="myRow">
                                        <div className='offset-md-9 col-md-3 button-right'>
                                            {(disableButton) ? <button type="submit" className="myregisterbtn btn btn-success" disabled>Update</button> : <button type="submit" className="myregisterbtn btn btn-success">Update</button>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomerChangePassword;