import '../../../css/login.css'
import '../../../css//formcontrol.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import LoaddingRound from '../../LoadingRound';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { ResetNewPasswordData } from '../../../redux/customerAuth';

function ResetPassword() {

    let { accesstoken, acessemail } = useParams();

    const [email, setEmail] = useState(acessemail);
    const [acesscode, setAcesscode] = useState(accesstoken);
    const [reset_password, setNewPassword] = useState("");
    let [disableField, setdisableField] = useState(true);

    
    const { loading } = useSelector((state) => state.customer);
    let [errorMsg, setErrorMsg] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function loginForm(event) {
        event.preventDefault();
        let loginDatas = { email, acesscode, reset_password };
        dispatch(ResetNewPasswordData(loginDatas)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/login");
            } else {
                setErrorMsg("Link Was Invalid Try again");
            }
        });
    };

    function checkPassword(data) {
        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;
        let errMsg = "";
        setdisableField(true);
        if (!data.match(uppercaseRegExp))
            errMsg = "At least one Uppercase\n";
        if (!data.match(lowercaseRegExp))
            errMsg += "At least one Lowercase\n";
        if (!data.match(digitsRegExp))
            errMsg += "At least one digit\n";
        if (!data.match(specialCharRegExp))
            errMsg += "At least one Special Characters\n";
        if (!data.match(minLengthRegExp))
            errMsg += "At least minumum 8 characters\n";
        setErrorMsg(errMsg);
        if (errMsg === "") {
            setNewPassword(data);
            setdisableField(false);
        }
    }


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Reset Password | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="login-cont myRow">

                    <div className="col-md-12 login-details">
                        <div className="cont-details">
                            <div className="heading-cont">ReSet Password</div>
                            <div className="body-cont" id="login">
                                <form id="loginForm" onSubmit={(event) => loginForm(event)}>
                                    <div className="form__group field">
                                        <input type="email" className="form__field" placeholder="Enter Email" id="useremail" required value={email} readOnly />
                                        <label className="form__label">Email</label>
                                    </div>
                                    <div className="form__group field">
                                        <input type="password" className="form__field" placeholder="Enter New Password" onChange={(e) => checkPassword(e.target.value)} id="password" required />
                                        <label className="form__label">New Password</label>
                                    </div>
                                    <div className="password_error">
                                        {errorMsg.split('\n').map((item, i) => (item !== "") ? <p key={i}>{item}</p> : "")}
                                    </div>
                                    <div className='myRow inputrow'>
                                        {(!disableField) ? <button type="submit" className="myloginbtn">Re-Set <i className="fas fa-arrow-right"></i></button> : <button type="submit" className="myloginbtn" disabled>Re-Set <i className="fas fa-arrow-right"></i></button>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
            {
                loading ? <LoaddingRound /> : ""
            }
        </>
    )
}
export default ResetPassword;