import { configureStore } from "@reduxjs/toolkit";
import customerReducer from "../redux/customerAuth";
import visitRequestReducer from "../redux/visitRequest";
import customerProfileReducer from "../redux/customerProfile";
import spcDataReducer from "../redux/spcData";

const store = configureStore({
    reducer:{
        customer: customerReducer,
        visitRequest: visitRequestReducer,
        customerProfile: customerProfileReducer,
        spcData: spcDataReducer
    }
});

export default store;