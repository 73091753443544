import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { editClient, getAllPreferedCare, getAllRelation, getclientdetails } from "../../../../redux/spcData";

function EditClient() {

    const [mobNav, setMobNav] = useState(false);
    const [prefredcare, setPreferedCare] = useState([]);
    const [relationdata, setRelationData] = useState([]);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [status, setStatus] = useState("");
    const [addressFL, setAddressFL] = useState("");
    const [addressSL, setAddressSL] = useState("");
    const [relation, setRelation] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [locality, setLocality] = useState("");
    const [navigationmarker, setNavigationMarker] = useState("");
    const [prferedhomedata, setPrferedHome] = useState("No Preferred");
    const [agerange, setAgeRange] = useState("");
    const [otherpre, setOtherPre] = useState("");
    const [AadharCard, setAadharCard] = useState("N/A");
    const [medicalcard, setMedicalCard] = useState("N/A");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { clientid } = useParams();

    function editClentFrom(event) {
        event.preventDefault();
        let customerid = JSON.parse(localStorage.getItem('customer-info')).customer_cid;
        let prferedhome = prferedhomedata;
        if (prferedhome === "Others") {
            prferedhome = prferedhome + " - " + otherpre;
        }
        let customerDate = { clientid, status, gender, email, lastName, middleName, firstName, addressFL, addressSL, city, state, country, zipcode, locality, customerid, relation, AadharCard, navigationmarker, prferedhome, agerange, medicalcard };
        dispatch(editClient(customerDate)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/clients");
            }
        });
    }

    React.useEffect(() => {
        dispatch(getclientdetails(clientid)).then((result) => {
            if (result.payload.error === "") {
                let clientdata = result.payload.data;
                setFirstName(clientdata.fname);
                setLastName(clientdata.lname);
                setMiddleName(clientdata.mname);
                setEmail(clientdata.email);
                setAddressFL(clientdata.fladdress);
                setAddressSL(clientdata.sladdress);
                setLocality(clientdata.locality);
                setCity(clientdata.city);
                setCountry(clientdata.country);
                setState(clientdata.state);
                setZipcode(clientdata.zipcode);
                setGender(clientdata.gender);
                setAadharCard(clientdata.aadhar);
                setNavigationMarker(clientdata.navigationmarker);
                setAgeRange(clientdata.agerange);
                setRelation(clientdata.relation);
                setMedicalCard(clientdata.medicalcard);
                setStatus(clientdata.status);
                if (clientdata.prferedhome.split(" - ").length > 1) {
                    if (clientdata.prferedhome.split(" - ")[0] === "Others") {
                        setPrferedHome("Others");
                        setOtherPre(clientdata.prferedhome.split(" - ")[1]);
                    } else {
                        setPrferedHome(clientdata.prferedhome);
                    }
                }
                else {
                    setPrferedHome(clientdata.prferedhome);
                }
            }
        });
        getRelation();
        getPrferedCare();
    }, []);

    const getPrferedCare = useCallback(() => {
        dispatch(getAllPreferedCare()).then((result) => {
            if (result.payload.error === "") {
                setPreferedCare(result.payload.data);
            }

        });
    }, [dispatch]);


    function getRelation() {
        dispatch(getAllRelation()).then((result) => {
            if (result.payload.error === "") {
                setRelationData(result.payload.data);
            }

        });
    };


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Member | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Members" Suboptions="" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Members", "Edit"]} />
                        <div className="fullformdetails big-panel">
                            <form id="addnewRequest" onSubmit={(event) => editClentFrom(event)}>
                                <div className="input-formtitle">
                                    Edit Member
                                </div>
                                <div className="myRow">
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">First Name</label>
                                            <input className="input-cell" defaultValue={firstName} required="required" type="text" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Middle Name</label>
                                            <input className="input-cell" defaultValue={middleName} type="text" placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Last Name</label>
                                            <input className="input-cell" defaultValue={lastName} required="required" type="text" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow">
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Status</label>
                                            <select className="input-cell" onChange={(e) => setStatus(e.target.value)} required value={status}>
                                                <option value="" disabled="">Select any one</option>
                                                <option value="active">Active</option>
                                                <option value="not active">Not Active</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Relationship with You</label>
                                            <select className="input-cell" onChange={(e) => setRelation(e.target.value)} required value={relation}>
                                                <option value="" disabled="">Select any Option</option>
                                                {
                                                    (relationdata.length !== 0) ?
                                                        relationdata.map((realtions, index) =>
                                                            <option key={index} value={realtions.relation}>{realtions.relation}</option>
                                                        ) :
                                                        ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Gender</label>
                                            <select className="input-cell" onChange={(e) => setGender(e.target.value)} required value={gender}>
                                                <option value="" disabled="">Select any one</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="others">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow">
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Age Range</label>
                                            <select className="input-cell" onChange={(e) => setAgeRange(e.target.value)} required value={agerange}>
                                                <option value="" disabled="">Select any one</option>
                                                <option value="10-20">10-20</option>
                                                <option value="21-30">21-30</option>
                                                <option value="31-40">31-40</option>
                                                <option value="41-50">41-50</option>
                                                <option value="51-60">51-60</option>
                                                <option value="61-70">61-70</option>
                                                <option value="71-80">71-80</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Preferred Home or Hospital</label>
                                            <select className="input-cell" onChange={(e) => setPrferedHome(e.target.value)} value={prferedhomedata}>
                                                <option value="No Preferred">No Preferred</option>
                                                {
                                                    (prefredcare.length !== 0) ?
                                                        prefredcare.map((prefer, index) =>
                                                            <option key={index} value={prefer.cname}>{prefer.cname}</option>
                                                        ) :
                                                        ""
                                                }
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        (prferedhomedata === "Others") ?
                                            <div className="col-md-4">
                                                <div className="input-formgroup">
                                                    <label className="input-label">Others Preferred Home or Hospital</label>
                                                    <input className="input-cell" defaultValue={otherpre} required="required" type="text" placeholder="Enter Preferred Home or Hospital" onChange={(e) => setOtherPre(e.target.value)} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                                <div className="myRow">
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Country</label>
                                            <input className="input-cell" defaultValue={country} required="required" type="text" placeholder="Enter Country" onChange={(e) => setCountry(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">State</label>
                                            <input className="input-cell" defaultValue={state} required="required" type="text" placeholder="Enter State" onChange={(e) => setState(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">City</label>
                                            <input className="input-cell" defaultValue={city} required="required" type="text" placeholder="Enter City" onChange={(e) => setCity(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow">
                                    <div className="col-md-6">
                                        <div className="input-formgroup">
                                            <label className="input-label">Address First Line</label>
                                            <input className="input-cell" defaultValue={addressFL} required="required" type="text" placeholder="Enter Address First Line" onChange={(e) => setAddressFL(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-formgroup">
                                            <label className="input-label">Address Second Line</label>
                                            <input className="input-cell" defaultValue={addressSL} type="text" placeholder="Enter Address Second Line" onChange={(e) => setAddressSL(e.target.value)} />
                                        </div>
                                    </div>

                                </div>
                                <div className="myRow">
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Locality</label>
                                            <input className="input-cell" defaultValue={locality} type="text" placeholder="Enter Locality" onChange={(e) => setLocality(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Zipcode</label>
                                            <input className="input-cell" defaultValue={zipcode} required="required" type="text" placeholder="Enter Zipcode" onChange={(e) => setZipcode(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Navigation Marker</label>
                                            <input className="input-cell" defaultValue={navigationmarker} type="text" placeholder="Enter Navigation Marker" onChange={(e) => setNavigationMarker(e.target.value)} />
                                        </div>
                                    </div>
                                </div>


                                <div className="myRow justify-content-md-end mob-but">
                                    <div className="col-md-2">
                                        <input type="submit" value="Update" className="my-button bg-green" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditClient;