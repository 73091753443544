import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFullVisit } from "../../../../redux/visitRequest";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getClientUsing } from "../../../../redux/spcData";

function ViewVisitRequest() {

    const [mobNav, setMobNav] = useState(false);
    let { vistid } = useParams();
    const dispatch = useDispatch();
    const [singleRequests, setSingleRequests] = useState([]);
    const [singleScheduleRequests, setSingleScheduleRequests] = useState([]);
    const [singleReportRequests, setSingleReportRequests] = useState([]);
    const [singleTaskRequests, setSingleTaskRequests] = useState([]);

    const [statusClass, setStatusClass] = useState("");
    const [statusScClass, setStatusSCClass] = useState("");

    function capitalEachword(mySentence) {
        const words = mySentence.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        return words.join(" ");
    }



    useEffect(() => {
        dispatch(getFullVisit(vistid)).then((result) => {
            if (result.payload.error === "") {
                let redata = result.payload.VisitRequest;
                let scdata = result.payload.VisitSchedule;
                let repodata = result.payload.VisitReport;
                let taskdata = result.payload.VisitTasks;
                setSingleRequests(redata);
                setSingleScheduleRequests(scdata);
                setSingleReportRequests(repodata);
                setSingleTaskRequests(taskdata);
                if (redata.status === "cancelled") {
                    setStatusClass("cancelleds");
                } else if (redata.status === "completed") {
                    setStatusClass("completeds");
                } else if (redata.status === "scheduled") {
                    setStatusClass("scheduleds");
                } else if (redata.status === "in progress") {
                    setStatusClass("progresse");
                } else if (redata.status === "draft") {
                    setStatusClass("drafts");
                }
                if (scdata.status === "assigned") {
                    setStatusSCClass("progresse");
                } else if (scdata.status === "completed") {
                    setStatusSCClass("completeds");
                }
                
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View Visit Request for {vistid} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Visit Request" Suboptions="View Request" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request", "View Request"]} />
                        <div className="fulldetailstable big-panel">
                            {
                                (singleRequests != "") ?
                                    <>
                                        <div className="input-formtitle">
                                            Details of Request {vistid}
                                        </div>
                                        <div className={"data-visit " + statusClass}>
                                            <div className="data-visit-title">Request Details</div>
                                            <div className="myRow">
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Request For</div>
                                                    {singleRequests.request_for} (
                                                    {(singleRequests !== null) ?
                                                        (singleRequests.memberfname !== undefined) ?
                                                            <>                                                                
                                                                {singleRequests.memberfname} {(singleRequests.membermname !== null) ? singleRequests.membermname : ""} {singleRequests.memberlname}
                                                            </> : "No Client Link" : "No Client Link"
                                                    })
                                                </div>
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Emergency</div>
                                                    {capitalEachword(singleRequests.emergency)}
                                                </div>
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Curent Status</div>
                                                    {capitalEachword(singleRequests.status)}
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Service Types</div>
                                                    {singleRequests.servicetype}
                                                </div>
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Visit Date & Time</div>
                                                    {singleRequests.visit_date} at {singleRequests.visit_time}
                                                </div>
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Preferred Associate</div>
                                                    {singleRequests.prefered_asso}
                                                </div>
                                            </div>
                                            <div className="myRow">
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Request Details</div>
                                                    {singleRequests.task_details}
                                                </div>
                                                <div className="data-cont col-md-4">
                                                    <div className="data-title">Additional Notes</div>
                                                    {singleRequests.aditianalnote}
                                                </div>
                                            </div>
                                        </div>
                                        {(singleScheduleRequests !== "No Data Found") ?
                                            <>
                                                <div className={"data-visit " + statusScClass}>
                                                    <div className="data-visit-title">Scheduled Details</div>
                                                    <div className="myRow">
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Request For</div>
                                                            {singleScheduleRequests.requestfor} ({singleRequests.memberfname} {(singleRequests.membermname !== null) ? singleRequests.membermname : ""} {singleRequests.memberlname})
                                                        </div>
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Curent Status</div>
                                                            {capitalEachword(singleScheduleRequests.status)}
                                                        </div>
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Scheduled Date & Time</div>
                                                            {singleScheduleRequests.sh_date} at {singleScheduleRequests.sh_time}
                                                        </div>
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Visit Coordinator</div>
                                                            {singleScheduleRequests.visit_co}
                                                        </div>
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Assigned Associate</div>
                                                            {singleScheduleRequests.assoname}
                                                        </div>
                                                    </div>
                                                    {
                                                        (singleScheduleRequests.note !== null) ?
                                                            <div className="myRow">
                                                                <div className="data-cont col-md-4">
                                                                    <div className="data-title">Note</div>
                                                                    {singleScheduleRequests.note}
                                                                </div>
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                            </>
                                            : ""}
                                        {(singleReportRequests !== "No Data Found") ?
                                            <>
                                                <div className={"data-visit " + statusScClass}>
                                                    <div className="data-visit-title">Report Details</div>
                                                    <div className="myRow">
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Visit Performed</div>
                                                            {singleReportRequests.vperby}
                                                        </div>
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Vist Start Date & Time</div>
                                                            {singleReportRequests.vsdate}
                                                        </div>
                                                        <div className="data-cont col-md-4">
                                                            <div className="data-title">Vist End Date & Time</div>
                                                            {singleReportRequests.vedate}
                                                        </div>
                                                    </div>
                                                    <div className="myRow">
                                                        <div className="data-cont col-md-6">
                                                            <div className="data-title">Visit Report Details</div>
                                                            {singleReportRequests.reportdetails}
                                                        </div>
                                                        {
                                                            (singleRequests.comments !== null) ?
                                                                <div className="data-cont col-md-6">
                                                                    <div className="data-title">Your Comment/Suggestions</div>
                                                                    {singleRequests.comments}
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                    <br />
                                                    <div className="myRow">
                                                        <div className="col-md-12">
                                                            <div className="my-table">
                                                                <table className="table table-striped my-table">
                                                                    <thead>
                                                                        <tr className="table-primary">
                                                                            <td className="normal_td table_blue">Requestetd Task</td>
                                                                            <td className="normal_td table_blue">Task Status</td>
                                                                            <td className="normal_td table_blue">Task Percentage</td>
                                                                            <td className="normal_td table_blue">Task Note</td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            (singleTaskRequests !== "No Data Found") ?
                                                                                singleTaskRequests.map((task, index) =>
                                                                                    <tr key={index}>
                                                                                        <td>{task.task}</td>
                                                                                        <td className="startwordcapital">{task.taskstatus}</td>
                                                                                        <td>{task.persentage} %</td>
                                                                                        <td>{task.tasknote}</td>
                                                                                    </tr>
                                                                                ) :
                                                                                <tr className="no-contact-data">
                                                                                    <td colSpan={10} className="errordata">No Data Found</td>
                                                                                </tr>
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : ""}
                                    </>
                                    :
                                    <div className="loading-gif">Loading...</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewVisitRequest;