import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getVisitRequest, updateVisitRequest } from "../../../../redux/visitRequest";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getAllMember, getAllRelation, getAllService, getClientUsing } from "../../../../redux/spcData";

function EditVisitRequest() {

    const [mobNav, setMobNav] = useState(false);
    const [requestFor, setRequestFor] = useState("");
    const [emergency, setEmergency] = useState("no");
    const [visitDate, setVisitDate] = useState("");
    const [visitTime, setVisitTime] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [preferedAsso, setPreferedAsso] = useState("");
    const [preferedAssoid, setPreferedAssoId] = useState("");
    const [requestDetails, setRequestDetails] = useState("");
    const [additinalNote, setAdditinalNote] = useState("");
    const [requestForID, setRequestForID] = useState("");
    const [clientname,setClentName]=useState("");

    let status;
    let { vistid } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [singleRequests, setSingleRequests] = useState([]);

    let [assouser, setAssoUser] = useState([]);
    let [serviceTypeData, setServiceTypeData] = useState([]);
    const [relationdata, setRelationData] = useState([]);
    const [client, setClent] = useState([]);

    function capitalEachword(mySentence) {
        const words = mySentence.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        return words.join(" ");
    }

    function blankRequest(event) {
        event.preventDefault();
    };
    function setCheckBox(event) {
        if (event.target.checked == true) {
            setEmergency("yes");
        } else {
            setEmergency("no");
        }
    }
    function updateRequest(type) {
        status = type;
        editVisitRequest();
    };

    const type = "associate";
    const getAssosData = useCallback(() => {
        setTimeout(() => {
            dispatch(getAllMember({ type })).then((result) => {
                if (result.payload.error === "") {
                    setAssoUser(result.payload.data);
                }
            });
        }, 2000);
    });

    function editVisitRequest() {
        let visit_id = vistid;
        let visitrequestData = { requestFor, emergency, visitDate, visitTime, serviceType, preferedAsso, requestDetails, additinalNote, status, preferedAssoid, requestForID };
        dispatch(updateVisitRequest({ visit_id, visitrequestData })).then((result) => {
            if (result.payload.error == "") {
                navigate("/customer/visit-request");
            }
        });
    };
    function getRelation() {
        dispatch(getAllRelation()).then((result) => {
            if (result.payload.error === "") {
                setRelationData(result.payload.data);
            }

        });
    };

    const getAsso = useCallback((event) => {
        let custo_id = event.target.value;
        setPreferedAssoId(custo_id);
        setPreferedAsso(event.target.options[event.target.selectedIndex].text);
    }, []);

    const setDataRequest = useCallback((value) => {
        let customerid = JSON.parse(localStorage.getItem('customer-info')).customer_cid;
        let relation = value;
        setRequestFor(relation);
        dispatch(getClientUsing({ customerid, relation })).then((result) => {
            if (result.payload.error === "") {
                setClent(result.payload.data);
            }
        });
    }, []);

    const getAllServices = useCallback(() => {
        setTimeout(() => {
            dispatch(getAllService()).then((result) => {
                if (result.payload.error === "") {
                    setServiceTypeData(result.payload.data);
                }
            });
        }, 2000);
    });

    useEffect(() => {
        getRelation();
        getAssosData();
        dispatch(getVisitRequest(vistid)).then((result) => {
            if (result.payload.error === "") {
                let redata = result.payload.data[0];
                setSingleRequests(redata);
                setRequestFor(redata.request_for);
                setEmergency(redata.emergency);
                setVisitDate(redata.visit_date);
                setVisitTime(redata.visit_time);
                setServiceType(redata.servicetype);
                setPreferedAsso(redata.prefered_asso);
                setRequestDetails(redata.task_details);
                setAdditinalNote(redata.aditianalnote);
                setPreferedAssoId(redata.prefered_assoid);
                setRequestForID(redata.request_forid);
                status = redata.status;
                let t=(redata.membermname !== null) ? redata.membermname : ""
                setClentName(redata.memberfname+" "+t+" "+redata.memberlname);
                getAllServices();
            }
        });
    }, []);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Visit Request for {vistid} | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Visit Request" Suboptions="Edit Request" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request", "Edit Request"]} />
                        <div className="fullformdetails big-panel">
                            {
                                (singleRequests != "") ?
                                    (singleRequests.status === "draft" || singleRequests.status === "in progress") ?
                                        <form id="addnewRequest" onSubmit={(event) => blankRequest(event)}>
                                            <div className="input-formtitle">
                                                Edit the request of - {vistid}
                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-5 offset-md-1">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Request For</label>
                                                        <input defaultValue={singleRequests.request_for+" ("+clientname+")"} className="input-cell" readOnly id="requestfor3" type="text" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="myRow">
                                                <div className="col-md-5 offset-md-1">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Is this an Emergency</label>
                                                        <label className="switch">
                                                            <input type="checkbox" onClick={(e) => setCheckBox(e)} id="emergency" defaultChecked={(singleRequests.emergency === "yes") ? true : false} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Curent Status</label>
                                                        <input defaultValue={capitalEachword(singleRequests.status)} className="input-cell" readOnly required="required" id="requestfor" type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="myRow justify-content-md-center">
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Visit Date</label>
                                                        <input defaultValue={singleRequests.visit_date} className="input-cell" required="required" id="visitdate" type="date" placeholder="Visit Date" onChange={(e) => setVisitDate(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Visit Time</label>
                                                        <input defaultValue={singleRequests.visit_time} className="input-cell" required="required" id="visittime" type="time" placeholder="Visit Time" onChange={(e) => setVisitTime(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow justify-content-md-center">
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Service Types</label>
                                                        <select className="input-cell" onChange={(e) => setServiceType(e.target.value)} required value={serviceType}>
                                                            <option value="" disabled="">Select any Service</option>
                                                            {
                                                                (serviceTypeData.length !== 0) ?
                                                                    serviceTypeData.map((serv, index) =>
                                                                        <option key={index} value={serv.service}>{serv.service}</option>
                                                                    ) :
                                                                    ""
                                                            }
                                                            <option value="Other Services">Other Services</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Preferred Associate</label>
                                                        <select className="input-cell" onChange={(e) => getAsso(e)} required value={preferedAssoid}>
                                                            <option value="" disabled="">Select any Associate</option>
                                                            {
                                                                (assouser.length !== 0) ?
                                                                    assouser.map((asso, index) =>
                                                                        <option key={index} value={asso.member_id}>{asso.fname} {asso.mname} {asso.lname}</option>
                                                                    ) :
                                                                    ""
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow justify-content-md-center">
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Request Details</label>
                                                        <textarea defaultValue={singleRequests.task_details} className="input-cell" required="required" id="requestdetails" type="text" placeholder="Request Details" onChange={(e) => setRequestDetails(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="input-formgroup">
                                                        <label className="input-label">Additional Notes</label>
                                                        <textarea defaultValue={singleRequests.aditianalnote} className="input-cell" required="required" id="addnotes" type="text" placeholder="Additional Notes" onChange={(e) => setAdditinalNote(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="myRow justify-content-md-end mob-but">
                                                {
                                                    (singleRequests.status === "draft") ?
                                                        <>
                                                            <div className="col-md-3">
                                                                <input type="button" value="Complete Request" className="my-button bg-green" onClick={(e) => updateRequest("in progress")} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <input type="button" value="Update as Draft" className="my-button bg-yellow" onClick={(e) => updateRequest("draft")} />
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-md-3">
                                                                <input type="button" value="Update Request" className="my-button bg-green" onClick={(e) => updateRequest(singleRequests.status)} />
                                                            </div>
                                                        </>
                                                }
                                            </div>
                                        </form>
                                        :
                                        <div className="client-data error loading-gif">You have no Rights for Edit due to Request Status is {capitalEachword(singleRequests.status)}</div>
                                    :
                                    <div className="loading-gif">Loading...</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditVisitRequest;