import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import SideMenu from "../../layout/SideMenu";
import React, { useState } from "react";
import HeaderNav from "../../layout/HeaderNav";
import { getallDraftRequest, getallValidRequest, updateStatusVisitRequest } from "../../../../redux/visitRequest";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function VisitRequest() {

    const [mobNav, setMobNav] = useState(false);
    const dispatch = useDispatch();

    const [validRequests, setValidRequest] = useState([]);
    const [draftRequests, setDraftRequest] = useState([]);
    let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_id;


    React.useEffect(() => {
        refreshTable();
    }, []);

    function refreshTable() {
        dispatch(getallValidRequest(customer_id)).then((result) => {
            if (result.payload.error === "") {
                setValidRequest(result.payload.data);
            }
        });
        dispatch(getallDraftRequest(customer_id)).then((result) => {
            if (result.payload.error === "") {
                setDraftRequest(result.payload.data);
            }
        });
    }
    function capitalEachword(mySentence) {
        const words = mySentence.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        return words.join(" ");
    }

    function updateRequestStatus(visit_id, visit_status) {
        dispatch(updateStatusVisitRequest({ visit_id, visit_status })).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
            }
        });
    };

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Customer Visit Request | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Visit Request" Suboptions="" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request"]} />
                        <div className="fulldetailstable big-panel">
                            <div className="button-container">
                                <Link to="/customer/visit-request/new" className="my-button bg-lightgreen"><i className="fas fa-plus"></i> New Request</Link>
                            </div>
                            <div className="table-heading">Visit Requests</div>
                            <div className="my-table">
                                <table className="table table-striped my-table">
                                    <thead>
                                        <tr className="table-success">
                                            <th className="icon-td"></th>
                                            <th className="icon-td"></th>
                                            <th>Request ID</th>
                                            <th>Request For</th>
                                            <th>Emergency</th>
                                            <th>Visit Date</th>
                                            <th>Visit Time</th>
                                            <th>Service Type</th>
                                            <th>Status</th>
                                            <th>Prefered Associate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (validRequests !== "") ?
                                                validRequests.map((validRequest, index) =>
                                                    <tr key={index}>
                                                        <td className="icon-td">
                                                            <Link to={"/customer/visit-request/view/" + validRequest.vrid} title="View Request"><i className="far fa-eye"></i></Link>
                                                        </td>
                                                        <td className="icon-td">
                                                            {(validRequest.status === "in progress") ?
                                                                <>
                                                                    <Link to={"/customer/visit-request/edit/" + validRequest.vrid} title="Edit Request"><i className="far fa-edit"></i></Link>
                                                                    <i className="fas fa-ban" title="Cancel Request" onClick={(e) => updateRequestStatus(validRequest.vrid, "cancelled")}></i>
                                                                </>
                                                                : ""}
                                                                {
                                                                 ((validRequest.vreid !== "" && validRequest.vreid !== null) && validRequest.comments === null)?
                                                                 <>
                                                                     <Link to={"/customer/visit-request/comment/" + validRequest.vrid} title="Your Comment/Suggestions"><i className="fas fa-comment-dots"></i></Link>                                                                   
                                                                 </>
                                                                 : ""   
                                                                }
                                                        </td>
                                                        <td>{validRequest.vrid}</td>
                                                        <td>{validRequest.request_for}</td>
                                                        <td>{validRequest.emergency}</td>
                                                        <td>{validRequest.visit_date}</td>
                                                        <td>{validRequest.visit_time}</td>
                                                        <td>{validRequest.servicetype}</td>
                                                        <td>{capitalEachword(validRequest.status)}</td>
                                                        <td>{validRequest.prefered_asso}</td>
                                                    </tr>
                                                ) : <tr><td colSpan="10"><div className="no-data">No Request Found</div></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="table-heading">Draft Requests</div>
                            <div className="my-table">
                                <table className="table table-striped my-table">
                                    <thead>
                                        <tr className="table-warning">
                                            <th></th>
                                            <th>Request ID</th>
                                            <th>Request For</th>
                                            <th>Emergency</th>
                                            <th>Visit Date</th>
                                            <th>Visit Time</th>
                                            <th>Service Type</th>
                                            <th>Prefered Associate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (draftRequests !== "") ?
                                                draftRequests.map((draftRequest, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <Link to={"/customer/visit-request/edit/" + draftRequest.vrid} title="Edit Request"><i className="far fa-edit"></i></Link>
                                                            <i className="fas fa-trash" title="Delete Request" onClick={(e) => updateRequestStatus(draftRequest.vrid, "deleted")}></i>
                                                        </td>
                                                        <td>{draftRequest.vrid}</td>
                                                        <td>{draftRequest.request_for}</td>
                                                        <td>{draftRequest.emergency}</td>
                                                        <td>{draftRequest.visit_date}</td>
                                                        <td>{draftRequest.visit_time}</td>
                                                        <td>{draftRequest.servicetype}</td>
                                                        <td>{draftRequest.prefered_asso}</td>
                                                    </tr>
                                                ) : <tr><td colSpan="8"><div className="no-data">No Request Found</div></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VisitRequest;