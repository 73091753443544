import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import '../../../../css/mainformcontrol.css';
import SideMenu from "../../layout/SideMenu";
import React, { useCallback, useEffect, useState } from "react";
import HeaderNav from "../../layout/HeaderNav";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newVisitRequest } from "../../../../redux/visitRequest";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getAllMember, getAllRelationClient, getAllService } from "../../../../redux/spcData";

function NewVisitRequest() {

    const [mobNav, setMobNav] = useState(false);
    const [requestFor, setRequestFor] = useState("");
    const [emergency, setEmergency] = useState("no");
    const [visitDate, setVisitDate] = useState("");
    const [visitTime, setVisitTime] = useState("");
    const [serviceType, setServiceType] = useState("");
    const [preferedAsso, setPreferedAsso] = useState("");
    const [preferedAssoid, setPreferedAssoId] = useState("0");
    const [requestDetails, setRequestDetails] = useState("");
    const [additinalNote, setAdditinalNote] = useState("");
    const [requestForID, setRequestForID] = useState("");
    const [dummyRequestFor, setDummyRequestFor] = useState("");


    let [assouser, setAssoUser] = useState([]);
    let [serviceTypeData, setServiceTypeData] = useState([]);
    const [relationdata, setRelationData] = useState([]);
    const [client, setClent] = useState([]);


    let status;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    function blankRequest(event) {
        event.preventDefault();
    };
    function setCheckBox(event) {
        if (event.target.checked === true) {
            setEmergency("yes");
        } else {
            setEmergency("no");
        }
    }

    React.useEffect(() => {
        getRelation();
        getAllServices();
        getAssosData();
    }, []);

    function newRequest() {
        status = "in progress";
        visitRequest();
    };
    function draftRequest() {
        status = "draft";
        visitRequest();
    };
    const type = "associate";
    const getAssosData = useCallback(() => {
        setTimeout(() => {
            dispatch(getAllMember({ type })).then((result) => {
                if (result.payload.error === "") {
                    setAssoUser(result.payload.data);
                }
            });
        }, 3000);
    });
    const getAllServices = useCallback(() => {
        setTimeout(() => {
            dispatch(getAllService()).then((result) => {
                if (result.payload.error === "") {
                    setServiceTypeData(result.payload.data);
                }
            });
        }, 2000);
    });

    function visitRequest() {
        let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
        let visitrequestData = { requestFor, emergency, visitDate, visitTime, serviceType, preferedAsso, requestDetails, additinalNote, status, preferedAssoid, requestForID };
        dispatch(newVisitRequest({ customer_id, visitrequestData })).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/visit-request");
            }
        });
    }

    function getRelation() {
        let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_cid;
        dispatch(getAllRelationClient(customer_id)).then((result) => {
            if (result.payload.error === "") {
                setRelationData(result.payload.data);
            }

        });
    };

    const getAsso = useCallback((event) => {
        let custo_id = event.target.value;
        setPreferedAssoId(custo_id);
        setPreferedAsso(event.target.options[event.target.selectedIndex].text);
    }, [])

    function setDataRequest(value) {
        setDummyRequestFor(value);
        setRequestFor(relationdata[value].relation);
        setRequestForID(relationdata[value].client_id);
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Customer New Visit Request | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Visit Request" Suboptions="New Request" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Visit Request", "New Request"]} />
                        <div className="fullformdetails big-panel">
                            <form id="addnewRequest" onSubmit={(event) => blankRequest(event)}>
                                <div className="input-formtitle">
                                    Create a New Visit Request
                                </div>
                                <div className="myRow">
                                    <div className="col-md-5 offset-md-1">
                                        <div className="input-formgroup">
                                            <label className="input-label">Request For</label>
                                            <select className="input-cell" onChange={(e) => setDataRequest(e.target.value)} required value={dummyRequestFor}>
                                                <option value="" disabled>Select any Option</option>
                                                {
                                                    (relationdata.length !== 0) ?
                                                        relationdata.map((realtions, index) =>
                                                            <option key={index} value={index}>{realtions.fname} {realtions.mname} {realtions.lname}</option>
                                                        ) :
                                                        ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        {
                                            (requestFor !== "") ?
                                                <div className="client-data"> Relation with You is {requestFor} </div>
                                                : <div className="client-data error"> Client not Added with this relation </div>
                                        }
                                    </div>
                                </div>
                                <div className="myRow">
                                    <div className="col-md-5 offset-md-1">
                                        <div className="input-formgroup">
                                            <label className="input-label">Is this an Emergency</label>
                                            <label className="switch">
                                                <input type="checkbox" onClick={(e) => setCheckBox(e)} id="emergency" />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Visit Date</label>
                                            <input className="input-cell" required="required" id="visitdate" type="date" placeholder="Visit Date" onChange={(e) => setVisitDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Visit Time</label>
                                            <input className="input-cell" required="required" id="visittime" type="time" placeholder="Visit Time" onChange={(e) => setVisitTime(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Service Types</label>
                                            <select className="input-cell" onChange={(e) => setServiceType(e.target.value)} required defaultValue={serviceType}>
                                                <option value="" disabled="">Select any Service</option>
                                                {
                                                    (serviceTypeData.length !== 0) ?
                                                        serviceTypeData.map((serv, index) =>
                                                            <option key={index} value={serv.service}>{serv.service}</option>
                                                        ) :
                                                        ""
                                                }
                                                <option value="Other Services">Other Services</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Preferred Associate</label>
                                            <select className="input-cell" onChange={(e) => getAsso(e)} required value={preferedAssoid}>
                                                <option value="" disabled="">Select any Associate</option>
                                                {
                                                    (assouser.length !== 0) ?
                                                        assouser.map((asso, index) =>
                                                            <option key={index} value={asso.member_id}>{asso.fname} {asso.mname} {asso.lname}</option>
                                                        ) :
                                                        ""
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Request Details</label>
                                            <textarea className="input-cell" required="required" id="requestdetails" type="text" placeholder="Request Details" onChange={(e) => setRequestDetails(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="input-formgroup">
                                            <label className="input-label">Additional Notes</label>
                                            <textarea className="input-cell" required="required" id="addnotes" type="text" placeholder="Additional Notes" onChange={(e) => setAdditinalNote(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-end mob-but">
                                    <div className="col-md-2">
                                        <input type="button" value="Create" className="my-button bg-green" onClick={(e) => newRequest(e.target.value)} />
                                    </div>
                                    <div className="col-md-2">
                                        <input type="button" value="Save as Draft" className="my-button bg-yellow" onClick={(e) => draftRequest(e.target.value)} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewVisitRequest;