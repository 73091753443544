import { useState } from "react";
import "../css/comingsoon.css";
function ComingSoon() {
    var countDownDate = new Date("Jan 1, 2024 0:0:0").getTime();
    const [countValue, setCountValue]=useState(" ");
    var x = setInterval(function() {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);        
        if (distance < 0) {
          clearInterval(x);
          setCountValue(" ");
        }else{
            setCountValue(days + "d " + hours + "h "+ minutes + "m " + seconds + "s ");
        }
      }, 1000);
    return (
        <>
            <div className="coming_soon">
                COMING SOON
                <hr />
                <p className="countbox">{countValue}</p>
            </div>
        </>
    )
}
export default ComingSoon;