import '../../../css/register.css';
import '../../../css/formcontrol.css';
import { Outlet } from 'react-router-dom';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import { useSelector } from 'react-redux';
import LoaddingRound from '../../LoadingRound';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function Register() {
    const { loading } = useSelector((state) => state.customer);
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Register | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <Header />
            <div className="total-body myRow bg_lightgray">
                <div className="register-cont myRow">
                    <div className="col-md-6 register-details">
                        <div className="cont-details">
                            <div className="heading-cont-reg">Join With Us</div>
                            <div className="body-cont">
                                <div className="col-details-cont"><span className='stepNumber'>Step 1 </span> Fill Your Own Information</div>
                                <div className="col-details-cont"><span className='stepNumber'>Step 2</span> Fill Your Residential Information</div>
                                <div className="col-details-cont"><span className='stepNumber'>Step 3</span> Fill Your Contact Information</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 register-form myRow">
                        <div className="heading-cont-reg">Customer Sign Up</div>
                        <Outlet></Outlet>
                    </div>
                </div>
                <Footer />
            </div>
            {
                loading ? <LoaddingRound /> : ""
            }
        </>
    )
}
export default Register