import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const updateContactCustomer = createAsyncThunk(
    'customer/profile/contact/update',
    async (contactData) => {
        let contactDatas = contactData;
        let result = await fetch(serverHost + "api/customer/updatecontact", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(contactDatas)
        })
        result = await result.json();
        return result;
    });
export const deleteContactCustomer = createAsyncThunk(
    'customer/profile/contact/delete',
    async (contact_id) => {
        let contactid = contact_id;
        let result = await fetch(serverHost + "api/customer/deletecontact/" + contactid, {
            method: "DELETE",
        })
        result = await result.json();
        return result;
    });
export const updateProfileCustomer = createAsyncThunk(
    'customer/profile/edit',
    async (customerData) => {
        let customerDatas = customerData;
        let result = await fetch(serverHost + "api/customer/updateprofile", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(customerDatas)
        })
        result = await result.json();
        if (result.error === "") {
            localStorage.setItem('customer-info', JSON.stringify(result.data));
        }
        return result;
    });
export const updatePasswordCustomer = createAsyncThunk(
    'customer/profile/password',
    async (customerData) => {
        let customerDatas = customerData;
        let result = await fetch(serverHost + "api/customer/updatepassword", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(customerDatas)
        })
        result = await result.json();
        return result;
    });

const customerProfileSlice = createSlice({
    name: 'customerProfile',
    initialState: {
        loading: false,
        customer: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteContactCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(deleteContactCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(deleteContactCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateContactCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(updateContactCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(updateContactCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updateProfileCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(updateProfileCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(updateProfileCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(updatePasswordCustomer.pending, (state) => {
                state.loading = true;
                state.customer = null;
                state.error = null;
            })
            .addCase(updatePasswordCustomer.fulfilled, (state, action) => {
                state.loading = false;
                state.customer = action.payload;
                state.error = null;
            })
            .addCase(updatePasswordCustomer.rejected, (state, action) => {
                state.loading = false;
                state.customer = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default customerProfileSlice.reducer;