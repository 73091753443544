import SideMenu from "../../layout/SideMenu";
import TopNav from "../../layout/TopNav";
import HeaderNav from "../../layout/HeaderNav";
import '../../../../css/customerlayout.css';
import '../../../../css/customerprofile.css';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProfileCustomer } from "../../../../redux/customerProfile";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetData, HelmetProvider } from 'react-helmet-async';
function CustomerEditProfile() {

    const customeruser = JSON.parse(localStorage.getItem('customer-info'));

    const [mobNav, setMobNav] = useState(false);

    const [firstName, setFirstName] = useState(customeruser.fname);
    const [middleName, setMiddleName] = useState(customeruser.mname);
    const [lastName, setLastName] = useState(customeruser.lname);
    const [email, setEmail] = useState(customeruser.email);
    const [gender, setGender] = useState(customeruser.gender);
    const [addressFL, setAddressFL] = useState(customeruser.fladdress);
    const [addressSL, setAddressSL] = useState(customeruser.sladdress);
    const [city, setCity] = useState(customeruser.city);
    const [state, setState] = useState(customeruser.state);
    const [country, setCountry] = useState(customeruser.country);
    const [zipcode, setZipcode] = useState(customeruser.zipcode);
    const [locality, setLocality] = useState(customeruser.locality);
    const [timezone, setTimezone] = useState(customeruser.timezone);

    let id = "";
    if (localStorage.getItem('customer-info')) {
        id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
    }
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function updateProfile(event) {
        event.preventDefault();
        let cid = id;
        let yourData = { cid, gender, email, lastName, middleName, firstName, addressFL, addressSL, city, state, country, zipcode, locality, timezone };
        dispatch(updateProfileCustomer(yourData)).then((result) => {
            if (result.payload.error === "") {
                navigate("/customer/profile");
            }
        });
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Your Profile | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Profile" Suboptions="" onMobNav={setMobNav}/>
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Your Profile", "Edit"]} />
                        <div className="fulldetailstable ">
                            <form id="addnewRequest" onSubmit={(event) => updateProfile(event)}>
                                <div className="input-formtitle">
                                    Update your Profile
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">First Name</label>
                                            <input className="input-cell" defaultValue={customeruser.fname} placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} id="firstname" required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Middle Name</label>
                                            <input className="input-cell" defaultValue={customeruser.mname} placeholder="Enter Middle Name" onChange={(e) => setMiddleName(e.target.value)} id="middlename" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Last Name</label>
                                            <input className="input-cell" defaultValue={customeruser.lname} placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} id="lastname" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-6">
                                        <div className="input-formgroup">
                                            <label className="input-label">Email</label>
                                            <input className="input-cell" defaultValue={customeruser.email} placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} id="email" required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Gender</label>
                                            <select className="input-cell" defaultValue={customeruser.gender} onChange={(e) => setGender(e.target.value)} id="gender" required >
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                                <option value="transgender">Transgender</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-4">
                                        <div className="input-formgroup">
                                            <label className="input-label">Address First Line</label>
                                            <input className="input-cell" defaultValue={customeruser.fladdress} placeholder="Enter Address First Line" onChange={(e) => setAddressFL(e.target.value)} id="addressfl" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4 offset-md-1">
                                        <div className="input-formgroup">
                                            <label className="input-label">Address Second Line</label>
                                            <input className="input-cell" defaultValue={customeruser.sladdress} placeholder="Enter Address Second Line" onChange={(e) => setAddressSL(e.target.value)} id="addresssl" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">City</label>
                                            <input className="input-cell" defaultValue={customeruser.city} placeholder="Enter City" onChange={(e) => setCity(e.target.value)} id="city" required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">State</label>
                                            <input className="input-cell" defaultValue={customeruser.state} placeholder="Enter State" onChange={(e) => setState(e.target.value)} id="state" required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Zipcode</label>
                                            <input className="input-cell" defaultValue={customeruser.zipcode} placeholder="Enter Zipcode" onChange={(e) => setZipcode(e.target.value)} id="zipcode" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center">
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Country</label>
                                            <input className="input-cell" defaultValue={customeruser.country} placeholder="Enter Country" onChange={(e) => setCountry(e.target.value)} id="country" required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Locality</label>
                                            <input className="input-cell" defaultValue={customeruser.locality} placeholder="Enter Locality" onChange={(e) => setLocality(e.target.value)} id="locality" required />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="input-formgroup">
                                            <label className="input-label">Timezone</label>
                                            <input className="input-cell" defaultValue={customeruser.timezone} placeholder="Enter Timezone" onChange={(e) => setTimezone(e.target.value)} id="timezone" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="myRow justify-content-md-center mob-but">
                                    <div className="col-md-2">
                                        <input type="submit" className="my-button bg-lightgreen" value="Update" />
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomerEditProfile;