import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb, no_img } from '../../../img/customeravatarimage';
function TopNav(props) {

    const customeruser = JSON.parse(localStorage.getItem('customer-info'));
    const custMale = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb];
    const custFemale = [lady_1_rb, lady_2_rb, lady_3_rb];
    const custAll = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb];

    let valueMobNav = props.valueMobNav;
    let onMobNav = props.onMobNav;

    const [menupopup, setMenuPopup] = useState(false);

    const [profilePic, setProfilePic] = useState(no_img);
    function updateCust() {
        if (customeruser.gender === "male") {
            setProfilePic(custMale[Math.floor(Math.random() * custMale.length)]);
        } else if (customeruser.gender === "female") {
            setProfilePic(custFemale[Math.floor(Math.random() * custFemale.length)]);
        } else {
            setProfilePic(custAll[Math.floor(Math.random() * custAll.length)]);
        }
    }
    function toggleNav() {
        onMobNav(!valueMobNav);
    }
    function offNav() {
        onMobNav(false);
    }
    useEffect(() => {
        updateCust();
    }, []);
    const navigate = useNavigate();

    function getGreet() {
        const currTime = new Date();
        let greet = "";
        if (currTime.getHours() < 6) {
            greet = "Night";
        } else if (currTime.getHours() < 12) {
            greet = "Morning";
        } else if (currTime.getHours() < 17) {
            greet = "AfterNoon";
        } else if (currTime.getHours() < 19) {
            greet = "Evening";
        } else {
            greet = "Night";
        }
        return greet;
    };

    const logout = () => {
        localStorage.clear();
        navigate("/customer/login");
    }

    return (
        <>
            <div className="topNavBar">
                <div className="mob-nav-toggle" onClick={() => [toggleNav(), setMenuPopup(false)]}>
                    <i className="fas fa-bars"></i>
                </div>
                <div className="greetType">
                    Welcome<br />
                    <span>Good {getGreet()}</span>
                </div>
                <div className="rightPart">
                    <div className="optionItem"><div className="chat-icon"><i className="fas fa-comment-dots"></i><span className="mark-num"></span></div></div>
                    <div className="optionItem"><div className="notification-icon"><i className="fas fa-bell"></i><span className="mark-num"></span></div></div>
                    <div className="cusName">{customeruser.fname}</div>
                    <div className="optionItem" onClick={() => [setMenuPopup(!menupopup), offNav()]}>
                        <img src={profilePic} alt="Profile Pic" className='profileimg' />
                    </div>
                </div>
                <div className={menupopup ? "popupcontainter popupshow" : "popupcontainter"}>
                    <div className='popupbody'>
                        {customeruser.fname} {customeruser.lname}<br />
                        <span>(Online)</span>
                    </div>
                    <div className='popupfooter'>
                        <Link to="/customer/profile" className='popupbut' onClick={() => setMenuPopup(!menupopup)}>Profile</Link>
                        <div className='popupbut' onClick={logout}>Logout</div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TopNav;