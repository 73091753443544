import SideMenu from "../../layout/SideMenu";
import TopNav from "../../layout/TopNav";
import HeaderNav from "../../layout/HeaderNav";
import '../../../../css/customerlayout.css';
import '../../../../css/customerprofile.css';
import ComingSoon from "../../../ComingSoon";
import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
function InvoicePay() {
    const [mobNav, setMobNav] = useState(false);
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Pay Invoice | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Invoice" Suboptions="Pay" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Invoice", "Pay"]} />
                        <div className="fulldetailstable">
                            <ComingSoon />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InvoicePay;