import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import SideMenu from "../../layout/SideMenu";
import HeaderNav from "../../layout/HeaderNav";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { getallTypeRequest } from "../../../../redux/visitRequest";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getScheduleVisit } from "../../../../redux/spcData";

function Dashboard() {

    const [acceptRequests, setAcceptRequest] = useState([]);
    const [pendingRequests, setPendingRequest] = useState([]);
    const [draftRequests, setDraftRequest] = useState([]);
    const [completedRequests, setCompletedRequest] = useState([]);
    const [scheduleVisit, setScheduleVisit] = useState([]);
    let customer_id = JSON.parse(localStorage.getItem('customer-info')).customer_id;

    const [mobNav, setMobNav] = useState(false);

    const dispatch = useDispatch();

    const refreshData = useCallback(() => {
        getinProgressData();
        getDraftData();
        getapprovedData();
        getCompletedRequest();
        getSchedule();
    });

    function getinProgressData() {
        let type = "in progress";
        dispatch(getallTypeRequest({ type, customer_id })).then((result) => {
            if (result.payload.error === "") {
                setPendingRequest(result.payload.data);
            }
        });
    }
    function getDraftData() {
        let type = "draft";
        dispatch(getallTypeRequest({ type, customer_id })).then((result) => {
            if (result.payload.error === "") {
                setDraftRequest(result.payload.data);
            }
        });
    }
    function getapprovedData() {
        let type = "scheduled";
        dispatch(getallTypeRequest({ type, customer_id })).then((result) => {
            if (result.payload.error === "") {
                setAcceptRequest(result.payload.data);
            }
        });
    }
    function getCompletedRequest() {
        let type = "completed";
        dispatch(getallTypeRequest({ type, customer_id })).then((result) => {
            if (result.payload.error === "") {
                setCompletedRequest(result.payload.data);
            }
        });
    }
    function getSchedule() {
        let customerid = JSON.parse(localStorage.getItem('customer-info')).customer_cid;
        dispatch(getScheduleVisit(customerid)).then((result) => {
            if (result.payload.error === "") {
                setScheduleVisit(result.payload.data);
            }
        });
    }
    React.useEffect(() => {
        refreshData();
    }, []);


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Customer Dashboard | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Dashboard" Suboptions="" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Dashboard"]} />
                        <div className="fulldetailstable">
                            <div className="myRow card-container">
                                <div className="col-md-3">
                                    <div className="card-total card-green">
                                        <div className="card-text">
                                            <div className="cards-title">Completed Request</div>
                                            <div className="card-value">{completedRequests.length}</div>
                                        </div>
                                        <div className="card-icon">
                                            <div className="card-iconcon">
                                                <i className="fas fa-check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card-total card-perple">
                                        <div className="card-text">
                                            <div className="cards-title">Scheduled Request</div>
                                            <div className="card-value">{acceptRequests.length}</div>
                                        </div>
                                        <div className="card-icon">
                                            <div className="card-iconcon">
                                                <i className="fas fa-thumbs-up"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card-total card-yellow">
                                        <div className="card-text">
                                            <div className="cards-title">InProgress Request</div>
                                            <div className="card-value">{pendingRequests.length}</div>
                                        </div>
                                        <div className="card-icon">
                                            <div className="card-iconcon">
                                                <i className="fas fa-spinner"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card-total card-blue">
                                        <div className="card-text">
                                            <div className="cards-title">Draft Request</div>
                                            <div className="card-value">{draftRequests.length}</div>
                                        </div>
                                        <div className="card-icon">
                                            <div className="card-iconcon">
                                                <i className="fas fa-exclamation"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="myRow">
                                <div className="table-heading">Upcoming Scheduled</div>
                                <div className="my-table">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr className="table-success">
                                                <th>Request ID</th>
                                                <th>Request For</th>
                                                <th>Assigned Associate</th>
                                                <th>Scheduled Date</th>
                                                <th>Scheduled Time</th>
                                                <th>Visit Coordinator</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (scheduleVisit != "") ?
                                                    scheduleVisit.map((schedule, index) =>
                                                        <tr key={index}>
                                                            <td>{schedule.vrid_i}</td>
                                                            <td>{schedule.requestfor}</td>
                                                            <td>{schedule.assoname}</td>
                                                            <td>{schedule.sh_date}</td>
                                                            <td>{schedule.sh_time}</td>
                                                            <td>{schedule.visit_co}</td>
                                                        </tr>
                                                    ) : <tr><td colSpan="8"><div className="no-data">No Request Found</div></td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Dashboard;