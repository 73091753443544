import SideMenu from "../../layout/SideMenu";
import TopNav from "../../layout/TopNav";
import HeaderNav from "../../layout/HeaderNav";
import '../../../../css/customerlayout.css';
import '../../../../css/customerprofile.css';
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { addContactCustomer, getContactCustomer } from "../../../../redux/customerAuth";
import { deleteContactCustomer, updateContactCustomer } from "../../../../redux/customerProfile";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
function CustomerContact() {

    const [mobNav, setMobNav] = useState(false);
    const dispatch = useDispatch();
    const [contacts, setContacts] = useState([]);
    const [contact_id, setContactId] = useState("0");
    const [contact_type, setContactType] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [editCon, setEditCon] = useState(<button type="submit" className="my-button bg-lightblue"><i className="fas fa-plus"></i> Add</button>);

    let id = "";
    if (localStorage.getItem('customer-info')) {
        id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
    }

    useEffect(() => {
        refreshTable();
    }, []);

    function refreshTable() {
        dispatch(getContactCustomer(id)).then((result) => {
            if (result.payload.error === "") {
                setContacts(result.payload.data);
            }
        });
    }

    function updateEditContact(contact_id, index) {
        if (contact_id === contacts[index].id) {
            setEditCon(<button type="submit" className="my-button bg-deeporange"><i className="fas fa-pen"></i> Edit</button>);
            setContactType(contacts[index].contact_type);
            setPhone(contacts[index].phone);
            setEmail(contacts[index].email);
            setWhatsapp(contacts[index].whatsapp);
            setContactId(contacts[index].id);
        }

    }
    function deleteContact(contact_id) {
        dispatch(deleteContactCustomer(contact_id)).then((result) => {
            if (result.payload.error === "") {
                refreshTable();
            }
        });
    }

    function addContact(event) {
        event.preventDefault();
        let cid = id;
        if (contact_id === "0") {
            let contactData = { contact_type, phone, email, whatsapp };
            dispatch(addContactCustomer({ cid, contactData })).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        } else {
            let contactData = { contact_id, contact_type, phone, email, whatsapp };
            dispatch(updateContactCustomer(contactData)).then((result) => {
                if (result.payload.error === "") {
                    refreshTable();
                }
            });
        }
        document.getElementById("ContactCustomer").reset();
        setEditCon(<button type="submit" className="my-button bg-lightblue"><i className="fas fa-plus"></i> Add</button>);
        setContactType("");
        setPhone("");
        setEmail("");
        setWhatsapp("");
        setContactId("0");

    };

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <title>Your Contacts | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Profile" Suboptions="Contacts" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Your Profile", "Contacts"]} />
                        <div className="fulldetailstable big-panel">
                            <div className="button-container">
                                <Link to={"/customer/profile/edit"} className="my-button bg-lightgreen"><i className="fas fa-pencil-alt"></i> Edit Profile</Link>
                                <Link to={"/customer/profile/changepassword"} className="my-button bg-deeporange"><i className="fas fa-key"></i> Change Password</Link>
                            </div>
                            <div className="myRow addcontactfield">
                                <form id="ContactCustomer" onSubmit={(event) => addContact(event)} className="myRow">
                                    <input type="hidden" defaultValue={contact_id} id="contactid" />
                                    <div className='col-md-3'>
                                        <div className="form__group field">
                                            <select value={contact_type} className="form__field" onChange={(e) => setContactType(e.target.value)} id="contacttype" required>
                                                <option value="" disabled >Select any one</option>
                                                <option value="Regular">Regular</option>
                                                <option value="Emergency">Emergency</option>
                                            </select>
                                            <label className="form__label">Contact Type</label>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form__group field">
                                            <input type="text" className="form__field" defaultValue={phone} placeholder="Enter Mobile Number" onChange={(e) => setPhone(e.target.value)} id="mobilenumber" required />
                                            <label className="form__label">Mobile Number</label>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form__group field">
                                            <input type="text" className="form__field" defaultValue={whatsapp} placeholder="Enter Whatsapp Number" onChange={(e) => setWhatsapp(e.target.value)} id="wpnumber" />
                                            <label className="form__label">Whatsapp Number</label>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className="form__group field">
                                            <input type="email" className="form__field" defaultValue={email} placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} id="email" required />
                                            <label className="form__label">Email</label>
                                        </div>
                                    </div>
                                    <div className='offset-md-9 col-md-3 button-right'>
                                        {editCon}
                                    </div>
                                </form>
                            </div>
                            <div className="myRow">
                                <div className="profile-title"> My Contact</div>
                                <div className="myRow">
                                    {
                                        (contacts.length !== 0) ?
                                            contacts.map((contact, index) =>
                                                <div className="col-md-3 contact-con" key={contact.id}>
                                                    <div className={(contact.contact_type === "Emergency") ? "profilecontact-card profilecontact-emergency" : "profilecontact-card profilecontact-regular"} >
                                                        <div className="profilecontact-type">
                                                            <i className="far fa-pen" title="Edit Contact" onClick={(e) => updateEditContact(contact.id, index)}></i>
                                                            <span className="head-text">{contact.contact_type}</span>
                                                            <i className="fas fa-trash" title="Delete Contact" onClick={(e) => deleteContact(contact.id)}></i>
                                                        </div>
                                                        <div className="profilecontact-phone"><div className="label">Phone</div><i className="fas fa-phone-alt"></i> {contact.phone}</div>
                                                        <div className="profilecontact-email"><div className="label">Email</div><i className="fas fa-at"></i> {contact.email}</div>
                                                        <div className="profilecontact-whatsapp"><div className="label">WhatsApp</div><i className="fab fa-whatsapp"></i> {contact.whatsapp}</div>
                                                    </div>
                                                </div>
                                            ) : <div className="no-contact-data">No Contact Added</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomerContact;