import SideMenu from "../../layout/SideMenu";
import TopNav from "../../layout/TopNav";
import HeaderNav from "../../layout/HeaderNav";
import '../../../../css/customerlayout.css';
import '../../../../css/customerprofile.css';
import { Link } from "react-router-dom";
import { man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb } from '../../../../img/customeravatarimage';
import React, { useEffect, useState } from "react";
import { getContactCustomer } from "../../../../redux/customerAuth";
import { useDispatch } from "react-redux";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function CustomerProfile() {

    const [mobNav, setMobNav] = useState(false);

    const customeruser = JSON.parse(localStorage.getItem('customer-info'));
    const custMale = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb];
    const custFemale = [lady_1_rb, lady_2_rb, lady_3_rb];
    const custAll = [man_2_rb, man_3_rb, man_4_rb, man_5_rb, man_6_rb, lady_1_rb, lady_2_rb, lady_3_rb];

    let id = "";
    const dispatch = useDispatch();

    const [contacts, setContacts] = useState([]);
    const [profilePic, setProfilePic] = useState("");
    function updateCust() {
        if (customeruser.gender === "male") {
            setProfilePic(custMale[Math.floor(Math.random() * custMale.length)]);
        } else if (customeruser.gender === "female") {
            setProfilePic(custFemale[Math.floor(Math.random() * custFemale.length)]);
        } else {
            setProfilePic(custAll[Math.floor(Math.random() * custAll.length)]);
        }
    }
    useEffect(() => {
        updateCust();
    }, []);

    if (localStorage.getItem('customer-info')) {
        id = JSON.parse(localStorage.getItem('customer-info')).customer_id;
    }

    useEffect(() => {
        dispatch(getContactCustomer(id)).then((result) => {
            if (result.payload.error === "") {
                setContacts(result.payload.data);
            }
        });
    }, []);

    return (
        <>

            <HelmetProvider>
                <Helmet>
                    <title>Your Profile | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>
            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Profile" Suboptions="" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Your Profile"]} />
                        <div className="fulldetailstable big-panel">
                            <div className="myRow profile-backgound">
                                <div className="fullpofile-image">
                                    <img src={profilePic} alt="Profile Pic" />
                                    <Link to="/customer/profile/edit" className="my-button bg-lightgreen"><i className="fas fa-user-edit"></i> Edit Profile</Link>
                                </div>
                                <div className="profile-details">
                                    <div className="profile-item">
                                        <label>Name</label>
                                        {customeruser.fname} {customeruser.mname} {customeruser.lname}
                                    </div>
                                    <div className="profile-item">
                                        <label>Email</label>
                                        {customeruser.email}
                                    </div>
                                    <div className="myRow">
                                        <div className="col-md-6">
                                            <div className="profile-item">
                                                <label>Address</label>
                                                {customeruser.fladdress}<br />
                                                {customeruser.sladdress}<br />
                                                {customeruser.city}, {customeruser.state}<br />
                                                Zip Code - {customeruser.zipcode}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="profile-item">
                                                <label>Country</label>
                                                {customeruser.country}
                                            </div>
                                            <div className="profile-item">
                                                <label>Locality  & Time Zone</label>
                                                {customeruser.locality} ( {customeruser.timezone} )
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="myRow">
                                <div className="profile-title"> My Contact</div>
                                <div className="myRow">
                                    {
                                        (contacts.length !== 0) ?
                                            contacts.map((contact) =>
                                                <div className="col-md-3" key={contact.id}>
                                                    <div className={(contact.contact_type === "Emergency") ? "profilecontact-card profilecontact-emergency" : "profilecontact-card profilecontact-regular"} >
                                                        <div className="profilecontact-type">{contact.contact_type}</div>
                                                        <div className="profilecontact-phone"><div className="label">Phone</div><i className="fas fa-phone-alt"></i> {contact.phone}</div>
                                                        <div className="profilecontact-email"><div className="label">Email</div><i className="fas fa-at"></i> {contact.email}</div>
                                                        <div className="profilecontact-whatsapp"><div className="label">WhatsApp</div><i className="fab fa-whatsapp"></i> {contact.whatsapp}</div>
                                                    </div>
                                                </div>
                                            ) : <div className="no-contact-data">No Contact Added</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomerProfile;