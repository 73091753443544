import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './component/customer/register/Register';
import Login from './component/customer/login/Login';
import RegisterStep1 from './component/customer/register/RegisterStep1';
import RegisterStep2 from './component/customer/register/RegisterStep2';
import RegisterStep3 from './component/customer/register/RegisterStep3';
import ProtectedCustomer from './component/customer/ProtectedCustomer';
import Home from './Home';
import VisitRequest from './component/customer/features/VisitRequest/VisitRequest';
import NewVisitRequest from './component/customer/features/VisitRequest/NewVisitRequest';
import DraftVisitRequest from './component/customer/features/VisitRequest/DraftVisitRequest';
import CustomerProfile from './component/customer/features/Profile/CustomerProfile';
import EditVisitRequest from './component/customer/features/VisitRequest/EditVisitRequest';
import CustomerContact from './component/customer/features/Profile/CustomerContact';
import CustomerChat from './component/customer/features/Profile/CustomerChat';
import Invoice from './component/customer/features/Invoice/Invoice';
import InvoicePay from './component/customer/features/Invoice/InvoicePay';
import InvoiceUpdate from './component/customer/features/Invoice/InvoiceUpdate';
import CustomerEditProfile from './component/customer/features/Profile/CustomerEditProfile';
import CustomerChangePassword from './component/customer/features/Profile/CustomerChangePassword';
import Dashboard from './component/customer/features/Dashboard/Dashboard';
import ViewVisitRequest from './component/customer/features/VisitRequest/ViewVisitRequest';
import PageNotFound from './component/layout/PageNotFoun';
import Clients from './component/customer/features/Clients/Clients';
import NewClient from './component/customer/features/Clients/NewClient';
import EditClient from './component/customer/features/Clients/EditClient';
import ViewClient from './component/customer/features/Clients/ViewClient';
import AddComment from './component/customer/features/VisitRequest/AddComment';
import WantJoin from './component/customer/login/WantJoin';
import UploadFile from './component/customer/features/Clients/UploadFile';
import ResetPassword from './component/customer/login/ResetPassword';


function App() {
  return (
    <>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='customer/login' element={<Login/>} />
            <Route path='customer/resetpassword/:accesstoken/:acessemail' element={<ResetPassword/>} />
            <Route path='customer/register' element={<Register />}>
              <Route index path='step1' element={<RegisterStep1/>} />
              <Route path='step2' element={<RegisterStep2 />} />
              <Route path='step3' element={<RegisterStep3 />} />
            </Route>

            <Route path='customer/dashboard' element={<ProtectedCustomer components={Dashboard}/>} />

            <Route path='customer/visit-request' element={<ProtectedCustomer components={VisitRequest}/>} />
            <Route path='customer/visit-request/new' element={<ProtectedCustomer components={NewVisitRequest}/>} />
            <Route path='customer/visit-request/draft' element={<ProtectedCustomer components={DraftVisitRequest}/>} />            
            <Route path='customer/visit-request/edit/:vistid' element={<ProtectedCustomer components={EditVisitRequest}/>} />
            <Route path='customer/visit-request/view/:vistid' element={<ProtectedCustomer components={ViewVisitRequest}/>} />
            <Route path='customer/visit-request/comment/:vistid' element={<ProtectedCustomer components={AddComment}/>} />

            <Route path='customer/profile' element={<ProtectedCustomer components={CustomerProfile}/>} />
            <Route path='customer/profile/contact' element={<ProtectedCustomer components={CustomerContact}/>} />
            <Route path='customer/profile/chat' element={<ProtectedCustomer components={CustomerChat}/>} />
            <Route path='customer/profile/edit' element={<ProtectedCustomer components={CustomerEditProfile}/>} />
            <Route path='customer/profile/changepassword' element={<ProtectedCustomer components={CustomerChangePassword}/>} />

            <Route path='customer/invoice' element={<ProtectedCustomer components={Invoice}/>} />
            <Route path='customer/invoice/pay' element={<ProtectedCustomer components={InvoicePay}/>} />
            <Route path='customer/invoice/checkupdate' element={<ProtectedCustomer components={InvoiceUpdate}/>} />

            <Route path='customer/clients' element={<ProtectedCustomer components={Clients}/>} />
            <Route path='customer/client/new' element={<ProtectedCustomer components={NewClient}/>} />          
            <Route path='customer/client/edit/:clientid' element={<ProtectedCustomer components={EditClient}/>} />
            <Route path='customer/client/view/:clientid' element={<ProtectedCustomer components={ViewClient}/>} />
            <Route path='customer/client/upload/:clientid' element={<ProtectedCustomer components={UploadFile}/>} />

            <Route path='associates/join' element={<WantJoin/>} />

            <Route path="*" element={<PageNotFound/>} />

          </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
