import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { serverHost } from "../Store/config";

export const getAllMember = createAsyncThunk(
    'employee/getAllMembers',
    async (data) => {
        let type = data.type;
        let memberuser_id = "000000";
        let result = await fetch(serverHost + "api/employee/getmembers/" + type + "/" + memberuser_id, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getAllRelationClient = createAsyncThunk(
    "admin/getrelation",
    async (customerid) => {
        let result = await fetch(serverHost + "api/customer/getclients/" + customerid, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getAllRelation = createAsyncThunk(
    "admin/getrelation",
    async () => {
        let result = await fetch(serverHost + "api/admin/getrelation", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getAllService = createAsyncThunk(
    "admin/getservice",
    async () => {
        let result = await fetch(serverHost + "api/admin/getservice", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getClientUsing = createAsyncThunk(
    'company/getclientuser',
    async (data) => {
        let relation = data.relation;
        let customerid = data.customerid;
        let result = await fetch(serverHost + "api/company/getclientuser/" + customerid + "/" + relation, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

export const getClientforCustomer = createAsyncThunk(
    'customer/getclients',
    async (data) => {
        let customerid = data.customerid;
        let result = await fetch(serverHost + "api/customer/getclients/" + customerid, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const addClient = createAsyncThunk(
    'client/addnewclient',
    async (clientData) => {
        let result = await fetch(serverHost + "api/client/addnewclient", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(clientData)
        })
        result = await result.json();
        return result;
    });
export const editClient = createAsyncThunk(
    'client/updateclient',
    async (clientData) => {
        let customerDatas = clientData;
        let result = await fetch(serverHost + "api/client/updateclient", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(customerDatas)
        })
        result = await result.json();
        return result;
    });


export const getAllPreferedCare = createAsyncThunk(
    "admin/getprefered",
    async () => {
        let result = await fetch(serverHost + "api/admin/getprefered", {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getclientdetails = createAsyncThunk(
    'client/getclientdetails',
    async (clientId) => {
        let result = await fetch(serverHost + "api/client/getclientdetails/" + clientId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const getScheduleVisit = createAsyncThunk(
    'customer/visitschedule',
    async (clientId) => {
        let result = await fetch(serverHost + "api/customer/visitschedule/" + clientId, {
            method: "Get",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });

export const uploadData = createAsyncThunk(
    'client/uploadfile',
    async (joinData) => {
        let result = await fetch(serverHost + "api/client/uploadfile", {
            method: "POST",
            body: joinData
        })
        result = await result.json();
        return result;
    });

const spcDataSlice = createSlice({
    name: 'visitRequest',
    initialState: {
        dataLoading: false,
        somedata: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadData.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(uploadData.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(uploadData.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getScheduleVisit.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getScheduleVisit.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getScheduleVisit.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllService.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getAllService.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getAllService.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getclientdetails.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getclientdetails.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getclientdetails.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(editClient.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(editClient.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(editClient.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllPreferedCare.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getAllPreferedCare.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getAllPreferedCare.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(addClient.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(addClient.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(addClient.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllMember.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getAllMember.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getAllMember.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getAllRelation.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getAllRelation.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getAllRelation.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getClientUsing.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getClientUsing.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getClientUsing.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
            .addCase(getClientforCustomer.pending, (state) => {
                state.dataLoading = true;
                state.somedata = null;
                state.error = null;
            })
            .addCase(getClientforCustomer.fulfilled, (state, action) => {
                state.dataLoading = false;
                state.somedata = action.payload;
                state.error = null;
            })
            .addCase(getClientforCustomer.rejected, (state, action) => {
                state.dataLoading = false;
                state.somedata = null;
                if (action.error.message) {
                    state.error = action.error.message;
                }
            })
    }
});
export default spcDataSlice.reducer;