import TopNav from "../../layout/TopNav";
import '../../../../css/customerlayout.css';
import '../../../../css/visitrequest.css';
import SideMenu from "../../layout/SideMenu";
import React, { useState } from "react";
import HeaderNav from "../../layout/HeaderNav";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { getClientforCustomer } from "../../../../redux/spcData";

function Clients() {

    const dispatch = useDispatch();

    const [clients, setClient] = useState([]);
    const [mobNav, setMobNav] = useState(false);

    React.useEffect(() => {
        refreshTable();
    }, []);

    function refreshTable() {
        let customerid = JSON.parse(localStorage.getItem('customer-info')).customer_cid;
        dispatch(getClientforCustomer({ customerid })).then((result) => {
            if (result.payload.error === "") {
                setClient(result.payload.data);
            }
        });
    }
    function capitalEachword(mySentence) {
        const words = mySentence.split(" ");
        for (let i = 0; i < words.length; i++)
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        return words.join(" ");
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Customer Visit Request | Parental Care India Pvt. Ltd.</title>
                </Helmet>
            </HelmetProvider>

            <div className="totalbody">
                <div className={mobNav ? "left-sideSection mob-nav-display" : "left-sideSection"}>
                    <SideMenu Options="Members" Suboptions="" onMobNav={setMobNav} />
                </div>
                <div className="right-sideSection">
                    <TopNav onMobNav={setMobNav} valueMobNav={mobNav} />
                    <div className="mainbody-container">
                        <HeaderNav navValue={["Members", "View"]} />
                        <div className="fulldetailstable big-panel">
                            <div className="button-container">
                                <Link to="/customer/client/new" className="my-button bg-lightgreen"><i className="fas fa-plus"></i> New Member</Link>
                            </div>
                            <div className="table-heading">Your Members</div>
                            <div className="my-table">
                                <table className="table table-striped my-table">
                                    <thead>
                                        <tr className="table-success">
                                            <th className="icon-td"></th>
                                            <th>Name</th>
                                            <th>Relation</th>
                                            <th>Gender</th>
                                            <th>Age</th>
                                            <th>Zipcode</th>
                                            <th>Navigation</th>
                                            <th>Status</th>
                                            <th>Prefered Home</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (clients != "") ?
                                                clients.map((client, index) =>
                                                    <tr key={index}>
                                                        <td className="icon-td">
                                                            <Link to={"/customer/client/edit/" + client.client_id} title="Edit Request"><i className="far fa-edit"></i></Link>
                                                            <Link to={"/customer/client/view/" + client.client_id} title="View Request"><i className="far fa-eye"></i></Link>
                                                        </td>
                                                        <td>{client.fname} {(client.mname != null) ? client.mname : ""} {client.lname}</td>
                                                        <td>{client.relation}</td>
                                                        <td>{capitalEachword(client.gender)}</td>
                                                        <td>{client.agerange}</td>
                                                        <td>{client.zipcode}</td>
                                                        <td>{client.navigationmarker}</td>
                                                        <td>{capitalEachword(client.status)}</td>
                                                        <td>{client.prferedhome}</td>
                                                    </tr>
                                                ) : <tr><td colSpan="10"><div className="no-data">No Member Found</div></td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Clients;